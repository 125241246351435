// npm i react-tsparticles tsparticles
// npm i tsparticles-slim


import Particles from "react-tsparticles"
import {useCallback, useMemo} from "react"
import {loadSlim} from "tsparticles-slim"

const ParticlesComponent = ()=>{
    const options = useMemo(()=>{
        return {
            fullScreen : {          //le full screen est optionnel
                enable: true, 
                zIndex: -1,  // définit la position "parmis les calques"
            },
            interactivity : {
                events : {
                /*    onClick: {
                        enable: true,  // autorise le click
                        mode : "push", // ajoute les particles au clic 
                    }, */
                    onHover : {
                        enable: true, // autorise le hover event 
                        mode: "repulse", // fait que les particles s'ecartent du curseur 
                    }
                },
            /*    modes: {
                    push: {
                        quantity: 5, // nbr de particles ajoutées au click
                    },
                    repulse: {
                        distance: 300 // distance de "propulsion" des particles du curseur au click
                    },
                }, */
            },
            particles : {
                links : {
                    enable : true, // relie les particles entre elles 
                    
                },
                move : {
                    enable : true, // fait que les particles bougent dans le canvas
                    speed: {min: 1, max: 3},
                },
                opacity : {
                    value: {min: 0.3, max: 0.7},  //tu connais 
                },
                size : {
                    value: { min: 1, max: 3} // randomize la taille des particles 
                }
            },
        }
    }, [])
    const particlesInit = useCallback((engine)=>{
        loadSlim(engine)
        
    }, [])
    
        return <Particles init={particlesInit} options={options}/>
}

export default ParticlesComponent