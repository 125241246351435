import React from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import {useState, useEffect} from 'react'
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fabar} from '@fortawesome/free-solid-svg-icons'


const Navbar = (props)=>{
    
    const [menuOpen, setMenuOpen] = useState(false)
    
   
    const handleToggle = () => {
    setMenuOpen(!menuOpen)
  }   
  
  const closeMenu = () => {
  setMenuOpen(false)
}
 
    
    
    
    return (
      
        <nav className="menuBurger">
        <button onClick={handleToggle}>{menuOpen ? (
            <MdClose id= "close" />
          ) : (
            <FiMenu id="menu" />
  )}</button>
        <ul id="ul" className={`menuNav ${menuOpen ? " showMenu" : ""}`}>
        <li><a href="#project" onClick={() => closeMenu()} >PROJECT</a></li>
        <li><a href="#token" onClick={() => closeMenu()} >TOKEN</a></li>
        <li><a to="#roadmap" onClick={() => closeMenu()} >ROADMAP</a></li>
        <li><a to="#partners" onClick={() => closeMenu()} >PARTNERS</a></li>
        </ul>
        </nav>
       
      
    )
}

export default Navbar




