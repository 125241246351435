import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import blueDesign from "../assets/img/blueDesign2.jpg"
import blueBack from "../assets/img/blueBack.png"
import blueBack2 from "../assets/img/blueBack2.png"
import roadmap2 from "../assets/img/roadmap2.png"
import light from "../assets/img/light.jpg"
import cz from "../assets/img/cz.png"
import elon from "../assets/img/elon2.png"
import justin from "../assets/img/agri.png"
import binance from "../assets/img/logo/binance.png"
import laposte from "../assets/img/logo/laposte.png"
import spacex from "../assets/img/logo/spacex.png"
import terroir from "../assets/img/logo/cochonou.png"
import bsc from "../assets/img/logo/bsc.jpeg"
import bsc2 from "../assets/img/bsc.png"
import coinbrain from '../assets/img/coinbrain.png'
import microsoft from "../assets/img/logo/microsoft.png"
import sncf from "../assets/img/logo/sncf.png"
//import back1 from "../assets/img/back1.jpeg"
import AnimatedText from 'react-animated-text-content';

const Home = (props)=>{

    
    const [isPopUp, setPopUp] = useState(false)
    
    useEffect(()=>{
      window.addEventListener('scroll', transition)
    }, [])
    
    const transition = ()=>{
      let tokenSection = document.querySelector("#token")
      if(window.scrollY > (tokenSection.offsetTop - 800)){
        tokenSection.style.backgroundColor = "white"
      }
    }
 //////////////////////////////////////////////////////////////   
    
    
      const transition3 = ()=>{
      let partnersSection = document.querySelector(".partners")
      console.log(window.scrollY)
      console.log(partnersSection.offsetTop)
      if(window.scrollY > (partnersSection.offsetTop - 916)){
          
          partnersSection.style.backgroundColor = "white"
      }
    }
     useEffect(()=>{
      window.addEventListener('scroll', transition3)
    }, [])
    
       const transition2 = ()=>{
      let partnersSection2 = document.querySelector(".partners")
      console.log(window.scrollY)
      console.log(partnersSection2.offsetTop)
      if(window.scrollY > (partnersSection2.offsetTop - 800)){
          
          partnersSection2.classList.add("add")
      }
    }
     useEffect(()=>{
      window.addEventListener('scroll', transition2)
    }, [])
    
   
  /*  
    const onHoverButton = () =>{
 
      if(isPopUp===false){
        setPopUp(true)
      } 
      
    }
    
    const offHoverButton = ()=>{
      setPopUp(false)
    }  */
    
    return(
        <div className="container">
            <section className="bandeau">
                 <h1 id="baseline">
                  <AnimatedText
                      type='words'
                      interval={0.05}
                      duration={1.5}
                      animation={{
                        x: '100px',
                        ease: 'ease-in-out',
                      }}
                    >
                      the first protocol powered by saucisson
                  </AnimatedText>
                </h1>
              
                <a target="_blank" href="https://pancakeswap.finance/info/token/0xf344a7ed7dabd12be220654470426b2f6a03c8d4">Buy token</a>
                
           
            </section>
            <div className="articles"> 
              <article className="article1"><p>In honor to the <span>saucisson</span> and to give him back what he bring to us daily, we decided to launch one of them <span>to the moon</span>. <br/> <br/> With our investors and scientifics from the most knowed spatial agencies we are actualy working on the <span>mission SCS</span> which consist to deploy our blockchain in space and by the way launch the famous saucisson in the exact direction of the moon.</p>
                <img id="blueDesign" src={blueDesign} alt="spaceship portal"></img>
              </article>
              
              <section id="token">
                <p><strong>SCS TOKEN will be use to paid very low gaz fees on the blockchain. Holders will be able to watch the liveStream of the mission SCS. Holders of more than 1000 SCS will be able to send us something that we'll launch to the moon during the mission SCS</strong></p>
                  <article className="articleToken1">
                          <table>
                          <tbody>
                            <tr>
                              <td>
                                token
                              </td>
                              <td>
                                $SCS
                              </td>
                            </tr>
                            <tr>
                              <td>
                                token type
                              </td>
                              <td>
                                BEP20
                              </td>
                            </tr>
                            <tr>
                              <td>
                                issuing price
                              </td>
                              <td>
                                $0.000630 USD
                              </td>
                            </tr>
                            <tr>
                              <td>
                                circulating supply
                              </td>
                              <td>
                                10 000.000 SCS
                              </td>
                            </tr>
                            <tr>
                              <td>
                                total supply
                              </td>
                              <td>
                                TBA
                              </td>
                            </tr>
                           
                        </tbody>
                      </table>
                      <a target="_blank" href="https://bscscan.com/token/0xf344a7ed7dabd12be220654470426b2f6a03c8d4#balances" className="buttonBsc"><img src={bsc2} alt=""/></a>
                  </article>
                
                  <iframe width="100%" height="1000" frameBorder="0" scrolling="no" src="https://coinbrain.com/embed/0xf344a7ed7dabd12be220654470426b2f6a03c8d4?chainId=56&theme=dark&padding=16&chart=1&trades=0"></iframe>
         
                 
       
              </section>
              
                <div className="roadmap" id="roadmap">
                    <AnimatedText
                      id="road"
                      type='words'
                      interval={0.05}
                      duration={1.5}
                      animation={{
                        x: '100px',
                        ease: 'ease-in-out',
                      }}
                    >
                      ROADMAP
                  </AnimatedText>
                    
                </div>
              <section className="sectionRoadmap">
                <div id="roadmapTop">
                  <p id="step1">Launch SCS token</p>
                  <p id="step3">Deploy the saucisson protocol in the space</p>
                </div>
                <img src={roadmap2} alt="roadmap"></img>
                <div id="roadmapBottom">
                  <p id="step2">Confirm the final steps of the mission with our partners</p>
                  <p id="step4">Launch the saucisson to the moon</p>
                </div>
              
              </section>
             
              <section className="partners" id="partners">
                <p id="trust">Partners</p>
                <div id="cz">
                <img src={cz} alt="c-z from binance"></img>
                <p>"Those guys are crazy and brillant in the same time, that's why i'm supporting them."<br/>CZ</p>
                </div>
              
                <div id="elon">
                <p>"Space industry is a serious market but it's great to reach our goals with fun !"<br/>Elon Musk</p>
                <img src={elon} alt="elon musk"></img>
                </div>
                
                <div id="justin">
                <img src={justin} alt="farmer"></img>
                <p>"I don't understand everything but i love what they are doing for the culture of the saucisson."<br/>Justin Fridou</p>
                </div>
              </section>
              <div id="logoPartners">
                <a href="https://www.spacex.com" target="_blank"><img src={spacex} alt="logo space-X" /></a>
                <a href="https://www.laposte.fr" target="_blank"><img src={laposte} alt="logo laposte" /></a>
                <a href="https://www.binance.com" target="_blank"><img src={binance} alt="logo binance" /></a>
                <a href="https://www.microsoft.com" target="_blank"><img src={microsoft} alt="logo microsoft" /></a>
                <a href="https://www.cochonou.fr" target="_blank"><img src={terroir} alt="logo cochonou" /></a>
              
              </div>
              
            </div>
              
        </div>
        )
   
}

export default Home

