import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import saucisse from "../assets/img/saucisse.png";
import Particles from '../containers/particles'
import Navbar from '../components/burger'




const Header = (props)=>{
    
    
    useEffect(()=>{
        window.addEventListener('scroll', descente)
        
    }, [])
    
    const descente = ()=>{
        let header = document.querySelector('header')
        if(window.scrollY >= 80){
            header.style.backgroundColor = "white"
            header.style.borderBottom = "1px solid black"
        }else{
            header.style.backgroundColor = "transparent"
            header.style.borderBottom = "none"
        }
    }
    
    
    ///////////// BURGER 
    
    
  

    const [header, setHeader] = useState(false);
    
    
    
    /////////////////////
    
    return (
        <header onScroll={descente}>
            <div id="div-logo">
             <img src={saucisse} id="img-logo"/>
                <p id="saucisson">SAUCISSON</p>
            </div>
            {window.innerWidth < 769 ?
                <Navbar /> :
            <nav>
                <a href='#baseline'>PROJECT</a>
                <a href='#token'>TOKEN</a>
                <a href='#roadmap'>ROADMAP</a>
                <a href='#partners'>PARTNERS</a>
            </nav>}
      
        </header>
    )
}

export default Header